import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import './blog-post.scss'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const keywords = ['saki hayashi', 'portfolio']
    // console.log(this.props.pageContext)
    console.log('post from blog index', post.frontmatter)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.frontmatter.description} keywords={keywords} />
        
        <div className="container">
          <hr
              style={{
                marginBottom: '1rem',
              }}
            />
          <h1 className="center">{post.frontmatter.title}</h1>
          <p
            style={{
              // ...scale(-1 / 5),
              display: `block`,
              textAlign: 'center',
              color: 'gray'
            }}
          >
            {post.frontmatter.date}
          </p>
          
          <Img fluid={post.frontmatter.featuredImg.childImageSharp.fluid} alt={post.frontmatter.title} />

          <hr style={{border: 0}} />

          <div className="container">
            <MDXRenderer>
                {post.body}
            </MDXRenderer>
          </div>
            

          <hr
            style={{
              marginBottom: '1rem',
            }}
          />

          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
              marginLeft: 0
            }}
          >
            <li style={{listStyle: 'none'}}>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li style={{listStyle: 'none'}}>
              {next && (
                <Link to={next.fields.slug} rel="next" >
                  {next.frontmatter.title} 
                </Link>
              )}
            </li>
          </ul>
        </div>
        
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const blogQuery = graphql`
  query($slug: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        category
        description
        date(formatString: "MMMM DD, YYYY")
        featuredImg {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      }
      body
    }
  }
`
